/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'product-order-card',
  templateUrl: './productOrderCard.component.html',
  styleUrls: ['./productOrderCard.component.scss'],
})
export class ProductOrderCardComponent implements OnInit {

  @Input() product: any;
  image: any;
  name: any;

  constructor(
    private cartSvc: CartService
  ) { }

  ngOnInit() {
    this.setNameImgProducts();
  }

  async setNameImgProducts() {
    const products = await this.cartSvc.getProductsById(this.product.id_foodtruck).then(res => res);
    if (products) {
      products.forEach(product => {
        if (product.id === this.product.id_product) {
          this.image = product.image;
          this.name = product.name;
        }
      });
    }
  }
}
