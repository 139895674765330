/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {

  @Input() title = '';
  @Input() text = '';
  @Input() btnCancel = '';
  @Input() btnSubmit = '';

  constructor(
    private popover: PopoverController,
  ) { }

  ngOnInit() {}

  confirm() {
    this.popover.dismiss(true);
  }

  closePopover() {
    this.popover.dismiss(null);
  }
}
