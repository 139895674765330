/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CartService } from 'src/app/shared/services/cart/cart.service';

@Component({
  selector: 'ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {

  @Input() ticket: any;
  @Input() foodtrucks: any;
  products = {};
  order: any;

  constructor(
    private cartSvc: CartService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.getAllProducts();
  }

  getOrder() {
    this.order = [];
    this.foodtrucks.forEach(foodtruck => {
      const products = [];
      this.ticket.products.forEach(ticketProduct => {
        if (ticketProduct.id_foodtruck === foodtruck.id) {
          products.push({
            price: parseFloat(ticketProduct.price),
            amount: parseFloat(ticketProduct.quantity),
            id: ticketProduct.id_product,
            stateOrder: ticketProduct.state_order,
            statePay: ticketProduct.state_pay,
            name: this.products[ticketProduct.id_product]
          });
        }
      });
      if (products.length !== 0) {
        this.order.push({
          name: foodtruck.name,
          cart: products
        });
      }
    });
  }

  async getAllProducts() {
    const products = await this.cartSvc.getAllProducts().then(res => res);
    if (products) {
      products.forEach(product => {
        this.products[product.id] = product.name;
      });
      this.getOrder();
    }
  }

  getFoodtruck(id) {
    let name = '';
    if (this.foodtrucks) {
      this.foodtrucks.forEach(foodtruck => {
        if (foodtruck.id === id) {
          name = foodtruck.name;
        }
      });
    }
    return name;
  }

  getTotalStand(cart) {
    let total = 0;
    cart.forEach(product => {
      total += (product.price * product.amount);
    });
    return total;
  }

  confirm() {
    this.popoverCtrl.dismiss(true);
  }

  closePopover() {
    this.popoverCtrl.dismiss(null);
  }
}
