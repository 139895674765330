import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ApiService } from './shared/services/api/api.service';
import { LanguageService } from './shared/services/language/language.service';

@Component({
  selector: 'app-root',
  template: `<ion-app><ion-router-outlet></ion-router-outlet></ion-app>`,
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private languageSvc: LanguageService,
    private apiSvc: ApiService
  ) {
    this.platform.ready().then(() => {
      this.languageSvc.setInitialLanguage();
      this.apiSvc.setInitialURL();
    });
  }
}
