import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/modules/shared.module';
import { IonicModule } from '@ionic/angular';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { PopoversRoutingModule } from './popovers-routing.module';

// Popovers
import { ConfirmComponent } from './popovers/confirm/confirm.component';
import { OrderComponent } from './popovers/order/order.component';
import { SetLanguagePopoverPage } from './popovers/setLanguage/setLanguage.page';
import { OrderProComponent } from './popovers/orderPro/orderPro.component';
import { TicketComponent } from './popovers/ticket/ticket.component';
import { OrderSummaryComponent } from './popovers/orderSummary/orderSummary.component';

@NgModule({
  declarations: [
    ConfirmComponent,
    OrderComponent,
    TicketComponent,
    OrderSummaryComponent,
    SetLanguagePopoverPage,
    OrderProComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    PopoversRoutingModule,
    ReactiveFormsModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: []
})
export class PopoversModule {}
