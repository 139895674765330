/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { OrdersService } from 'src/app/shared/services/orders/orders.service';

@Component({
  selector: 'order-pro',
  templateUrl: './orderPro.component.html',
  styleUrls: ['./orderPro.component.scss'],
})
export class OrderProComponent implements OnInit {

  @Input() order: any;

  @Input() foodtrucks: any;
  @Input() products: any;
  @Input() barmans: any;
  @Input() tables: any;

  barman?;
  oldBarman?;

  states = ['waiting', 'inProgress', 'inDelivery', 'delivered', 'canceledShop'];
  state?;
  oldState?;

  annotations?= '';

  constructor(
    private popoverCtrl: PopoverController,
    private ordersSvc: OrdersService,
  ) { }

  ngOnInit() {
    this.barman = this.order.products[0].barman_id;
    this.oldBarman = this.order.products[0].barman_id;

    this.state = this.getState();
    this.oldState = this.getState();

    this.getAnnotations();
  }

  getAnnotations() {
    this.order.products.forEach(product => {
      if (product.annotations) {
        this.annotations = product.annotations;
      }
    });
  }

  getState() {
    if (!this.order.products[0].barman_id) {
      return 'waiting';
    } else {
      return this.order.products[0].state_order;
      switch (this.order.products[0].state_order) {
        case 'inDelivery':
        case 'inProgress':
        case 'canceledMe':
        case 'canceledShop':
          return this.order.products[0].state_order;
        case 'delivered':
          if (this.order.products[0].state_pay === '0') {
            return 'delivered';
          }
          return 'charged';
      }
    }
  }

  getFoodtruck(id) {
    let name = '';
    if (this.foodtrucks) {
      this.foodtrucks.forEach(foodtruck => {
        if (foodtruck.id === id) {
          name = foodtruck.name;
        }
      });
    }
    return name;
  }

  getBarman(id) {
    let name = '';
    if (this.barmans) {
      this.barmans.forEach(barman => {
        if (id === barman.id) {
          name = barman.name;
          return name;
        }
      });
    }
    return name;
  }

  getTable(id) {
    let name = '';
    if (this.tables) {
      this.tables.forEach(table => {
        if (id === table.id) {
          name = table.name;
          return name;
        }
      });
    }
    return name;
  }

  charge() {
    const petitionLines = [];
    this.order.products.forEach(product => {
      petitionLines.push(product.id);
    });
    this.ordersSvc.setStatePayed(petitionLines).then(res => {
      if (!res.error) {
        this.popoverCtrl.dismiss(true);
      }
    });
  }

  confirm() {
    let noChangeState = true;
    if (this.state !== this.oldState) {
      noChangeState = false;
      const petitionLines = [];
      this.order.products.forEach(product => {
        petitionLines.push(product.id);
      });
      this.ordersSvc.setStateOrder(this.state, petitionLines).then(res => {
        if (res !== null) {
          return true;
        }
        return false;
      });
    }
    let noChangeBarman = true;
    if (this.barman !== this.oldBarman) {
      noChangeBarman = false;
      const petitionLines = [];
      this.order.products.forEach(product => {
        petitionLines.push(product.id);
      });
      this.ordersSvc.setBarmanToOrder(this.barman, petitionLines).then(res => {
        if (res !== null) {
          return true;
        }
        return false;
      });
    }
    if (noChangeState && noChangeBarman) {
      this.closePopover();
    } else {
      this.popoverCtrl.dismiss(true);
    }
  }

  closePopover() {
    this.popoverCtrl.dismiss(null);
  }
}
