import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageProduct'
})
export class ImageProductPipe implements PipeTransform {

  transform(value: string): string {
    return value ? 'https://congress-wtk.s3-eu-west-1.amazonaws.com/foodtrucks/products/' + value : '' ;
  }

}
