/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  public url = 'https://caapi.woutick.es';

  constructor(
    private http: HttpClient,
    public alertController: AlertController,
    public translateService: TranslateService,
  ) { }

  public getOrdersByFoodtruck(idFoodtruck): Promise<any> {
    return this.http.get(this.url + '/congress/historyorders/' + idFoodtruck, {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result['message'], (err) => err);
  }

  public getBarmans(id): Promise<any> {
    return this.http.get(this.url + '/congress/barmanlist/' + id, {
      headers: new HttpHeaders({
        'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
      })
    }).toPromise().then((result) => result['barmans'], (err) => err);
  }

  public setBarmanToOrder(idBarman, orders): Promise<any> {
    const json = {
      barman_id: idBarman,
      petition_lines: orders
    };
    return this.http.put(this.url + '/congress/manager',
      JSON.stringify(json),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise().then((result) => result, (err) => {
        this.presentAlertError(err.error.errorType);
        return null;
      });
  }

  public assignToOrder(orders): Promise<any> {
    const json = {
      petition_lines: orders
    };
    return this.http.put(this.url + '/congress/barmangetorder ',
      JSON.stringify(json),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise().then((result) => result, (err) => err);
  }

  public setStatePayed(orders): Promise<any> {
    const json = {
      state_pay: true,
      petition_lines: orders
    };
    return this.http.post(this.url + '/congress/paymentstaff ',
      JSON.stringify(json),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise().then((result) => result, (err) => err);
  }

  public setStateOrder(state, orders): Promise<any> {
    const json = {
      state_order: state,
      petition_lines: orders
    };
    return this.http.put(this.url + '/congress/stateorderupdate ',
      JSON.stringify(json),
      {
        headers: new HttpHeaders({
          'X-Access-Token': 'Bearer ' + localStorage.getItem('selecteduserJWT')
        })
      }).toPromise().then((result) => result, (err) => {
        this.presentAlertError(err.error.errorType);
        return null;
      });
  }

  async presentAlertError(errorType) {
    const title = await this.translateService.get('ORDERS.alert.error.title').toPromise()
      .then((value) => value);

    const message = await this.translateService.get('ORDERS.alert.error.message').toPromise()
      .then((value) => value);

    if (title && message) {
      const alert = await this.alertController.create({
        header: title,
        message: message + ' <br>(' + errorType + ')',
        buttons: ['Ok']
      });

      await alert.present();
    }
  }
}
