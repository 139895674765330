/* eslint-disable @angular-eslint/component-selector */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {

  @Output() sendQuery = new EventEmitter<any>();
  @Input() query: any = '';

  @Input() hideSearchbar = true;

  constructor() { }

  ngOnInit() {}

}
