/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CartService } from 'src/app/shared/services/cart/cart.service';

@Component({
  selector: 'order-summary',
  templateUrl: './orderSummary.component.html',
  styleUrls: ['./orderSummary.component.scss'],
})
export class OrderSummaryComponent implements OnInit {

  @Input() order: any;
  @Input() paymentMethod: any;

  total = 0;

  constructor(
    private popoverCtrl: PopoverController,
    private cartSvc: CartService
  ) { }

  ngOnInit() {
    this.total = this.cartSvc.getTotal();
  }

  confirm() {
    this.popoverCtrl.dismiss(true);
  }

  closePopover() {
    this.popoverCtrl.dismiss(null);
  }

  getTotalStand(cart) {
    let total = 0;
    cart.forEach(product => {
      total += (product.price * product.amount);
    });
    return total;
  }

}
