/* eslint-disable @angular-eslint/component-selector */
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'cart-fab-button',
  templateUrl: './cartFabButton.component.html',
  styleUrls: ['./cartFabButton.component.scss'],
})
export class CartFabButtonComponent implements OnInit, AfterViewChecked {

  cart = [];
  cartItemCount: BehaviorSubject<number>;

  constructor(
    private cartSvc: CartService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.setCart();
  }

  ngAfterViewChecked(): void {
    this.setCart();
    this.cdRef.detectChanges();
  }

  setCart() {
    this.cart = this.cartSvc.getCart();
    this.cartItemCount = this.cartSvc.getCartItemCount();
  }

  getTotal() {
    return this.cartSvc.getTotal();
  }

  goToCart() {
    this.router.navigate(['/cart']);
  }
}
