import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../../services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private router: Router,
    private apiService: ApiService
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.apiService.checkToken()
        .then((userLoggedIn) => {
          if (!userLoggedIn) {
            resolve(true);
          }
          else {
            this.router.navigate(['/']);
            resolve(false);
          }
        }, () => {
          resolve(true);
        });
    });
  }

}
