/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SetLanguagePopoverPage } from '../../pages/popovers/setLanguage/setLanguage.page';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'language-selection',
  templateUrl: './languageSelection.component.html',
  styleUrls: ['./languageSelection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {

  @Input() sidemenu = false;

  constructor(
    private popoverCtrl: PopoverController,
    public languageService: LanguageService
    ) { }

  ngOnInit() {}

  async openLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: SetLanguagePopoverPage,
      event: ev
    });
    await popover.present();
  }
}
