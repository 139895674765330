/* eslint-disable @angular-eslint/component-selector */
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { CartService } from 'src/app/shared/services/cart/cart.service';

@Component({
  selector: 'order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {

  @Input() order: any;
  @Input() foodtrucks: any;
  image?;
  name?;

  constructor(
    private cartSvc: CartService,
    private popoverCtrl: PopoverController
  ) { }

  ngOnInit() {
    this.setNameImgProducts();
  }

  async setNameImgProducts() {
    const products = await this.cartSvc.getProductsById(this.order.idFoodtruck).then(res => res);
    if (products) {
      products.forEach(product => {
        this.order.products.forEach(orderProduct => {
          if (product.id === orderProduct.id_product) {
            this.image = product.image;
            this.name = product.name;
          }
        });
      });
    }
  }

  getFoodtruck(id) {
    let name = '';
    if (this.foodtrucks) {
      this.foodtrucks.forEach(foodtruck => {
        if (foodtruck.id === id) {
          name = foodtruck.name;
        }
      });
    }
    return name;
  }

  confirm() {
    this.popoverCtrl.dismiss(true);
  }

  closePopover() {
    this.popoverCtrl.dismiss(null);
  }
}
